import "core-js/modules/es.array.push.js";
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-9f04c282"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "nav-right"
};
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
// import { throttle } from '@/utils'
import { throttle } from 'lodash/function';
import i18n from '@/language/index';
import { useGlobalStore } from '@/store';
export default {
  __name: 'Header',
  props: {
    backgroundColor: {
      type: String
    },
    noOpaque: {
      type: Boolean
    }
  },
  setup(__props) {
    const route = useRoute();
    const router = useRouter();
    const scrollTop = ref(0);
    const showAIHeuristicCardMenu = ref(false);
    const globalStore = useGlobalStore();
    function getScroll(e) {
      scrollTop.value = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    }
    function toUrl(url) {
      if (url === route.path) {
        window.location.reload();
      } else {
        router.push(url);
      }
    }
    const handleLanguage = () => {
      i18n.global.locale.value = i18n.global.locale.value === 'en' ? 'zh' : 'en';
      localStorage.setItem('language', i18n.global.locale.value);
      globalStore.setValue(i18n.global.locale.value);
    };

    // const openPage = () => {
    //   window.location.href = 'http://pangu.didalab.cn/inspiration'
    // }

    onMounted(() => {
      window.addEventListener('scroll', throttle(getScroll, 300));
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["header", {
          opaque: !__props.backgroundColor && scrollTop.value > 400,
          noOpaque: __props.noOpaque && scrollTop.value > 400
        }]),
        style: _normalizeStyle({
          backgroundColor: __props.backgroundColor
        })
      }, [_createElementVNode("a", {
        class: _normalizeClass(["logo", {
          active: _unref(route).path === '/'
        }]),
        onClick: _cache[0] || (_cache[0] = $event => _unref(router).push('/'))
      }, null, 2), _createElementVNode("div", {
        class: _normalizeClass(["nav-item", {
          active: _unref(route).path === '/'
        }]),
        onClick: _cache[1] || (_cache[1] = $event => _unref(router).push('/'))
      }, _toDisplayString(_ctx.$t('header.home')), 3), _createElementVNode("div", {
        class: _normalizeClass(["nav-item", {
          active: _unref(route).path === '/CrePoster'
        }]),
        onClick: _cache[2] || (_cache[2] = $event => _unref(router).push('/CrePoster'))
      }, _toDisplayString(_ctx.$t('header.poster')), 3), _createElementVNode("div", {
        class: _normalizeClass(["nav-item", {
          active: _unref(route).path === '/CopyrightProtection'
        }]),
        onClick: _cache[3] || (_cache[3] = $event => toUrl('/CopyrightProtection'))
      }, _toDisplayString(_ctx.$t('header.Copyright')), 3), _createElementVNode("div", {
        class: _normalizeClass(["nav-item", {
          active: _unref(route).path === '/Artalk'
        }]),
        onClick: _cache[4] || (_cache[4] = $event => _unref(router).push('/Artalk'))
      }, _toDisplayString(_ctx.$t('header.arTalk')), 3), _createElementVNode("div", {
        class: _normalizeClass(["nav-item", {
          active: _unref(route).path === '/AIHeuristicCard/home' || _unref(route).path === '/AIHeuristicCard/heuristicCard' || _unref(route).path === '/AIHeuristicCard/gameMap' || _unref(route).path === '/AIHeuristicCard/aiDesign'
        }]),
        onMouseenter: _cache[9] || (_cache[9] = $event => showAIHeuristicCardMenu.value = true),
        onMouseleave: _cache[10] || (_cache[10] = $event => showAIHeuristicCardMenu.value = false)
      }, [_createTextVNode(" Hello!AI "), showAIHeuristicCardMenu.value ? (_openBlock(), _createElementBlock("ul", {
        key: 0,
        class: _normalizeClass(["nav-item second-menu", _unref(i18n).global.locale.value === 'en' ? 'second-menu-en' : ''])
      }, [_createElementVNode("li", {
        onClick: _cache[5] || (_cache[5] = $event => _unref(router).push('/AIHeuristicCard/home'))
      }, _toDisplayString(_ctx.$t('aiHeuristicCard.home.menu.home')), 1), _createElementVNode("li", {
        onClick: _cache[6] || (_cache[6] = $event => _unref(router).push('/AIHeuristicCard/heuristicCard'))
      }, _toDisplayString(_ctx.$t('aiHeuristicCard.home.menu.heuristicCards')), 1), _createElementVNode("li", {
        onClick: _cache[7] || (_cache[7] = $event => _unref(router).push('/AIHeuristicCard/gameMap'))
      }, _toDisplayString(_ctx.$t('aiHeuristicCard.home.menu.gameMapComponents')), 1), _createElementVNode("li", {
        onClick: _cache[8] || (_cache[8] = $event => _unref(router).push('/AIHeuristicCard/aiDesign'))
      }, _toDisplayString(_ctx.$t('aiHeuristicCard.home.menu.aIDesignSprintComponents')), 1)], 2)) : _createCommentVNode("", true)], 34), _createElementVNode("div", {
        class: _normalizeClass(["nav-item", {
          active: _unref(route).path === '/Game'
        }]),
        onClick: _cache[11] || (_cache[11] = $event => _unref(router).push('/Game'))
      }, "Hello!AI@K12", 2), _createElementVNode("div", {
        class: _normalizeClass(["nav-item", {
          active: _unref(route).path === '/Resources'
        }]),
        onClick: _cache[12] || (_cache[12] = $event => _unref(router).push('/Resources'))
      }, _toDisplayString(_ctx.$t('header.Dataset')), 3), _createElementVNode("div", {
        class: _normalizeClass(["nav-item", {
          active: _unref(route).path === '/AboutUs'
        }]),
        onClick: _cache[13] || (_cache[13] = $event => _unref(router).push('/AboutUs'))
      }, _toDisplayString(_ctx.$t('header.AboutUs')), 3), _createElementVNode("div", _hoisted_1, [_createElementVNode("button", {
        type: "button",
        class: _normalizeClass(["nav-right-item", _unref(i18n).global.locale.value === 'en' ? 'cnLanguage' : 'language']),
        onClick: handleLanguage
      }, null, 2)])], 6);
    };
  }
};