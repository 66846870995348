import { createI18n } from 'vue-i18n'
import zh from './zh-ch'
import en from './en'
// ai启发式卡片项目
import aiDesignZh from '@/language/AIHeuristicCard/zh-ch/aiDesign'
import aiDesignEn from '@/language/AIHeuristicCard/en/aiDesign'
import gameMapZh from '@/language/AIHeuristicCard/zh-ch/gameMap'
import gameMapEn from '@/language/AIHeuristicCard/en/gameMap'
import homeZh from '@/language/AIHeuristicCard/zh-ch/home'
import homeEn from '@/language/AIHeuristicCard/en/home'
import heuristicCardZh from '@/language/AIHeuristicCard/zh-ch/heuristicCard'
import heuristicCardEn from '@/language/AIHeuristicCard/en/heuristicCard'

import artalkZh from '@/language/Artalk/zh-ch/home'
import artalkEn from '@/language/Artalk/en/home' // 尚未翻译

const i18n = createI18n({
  locale: localStorage.getItem('language') || 'zh',
  legacy: false,
  globalInjection: true,
  messages: {
    zh: {
      ...zh,
      aiHeuristicCard: {
        aiDesign: aiDesignZh,
        gameMap: gameMapZh,
        heuristicCard: heuristicCardZh,
        home: homeZh
      },
      artalkText: artalkZh
    },
    en: {
      ...en,
      aiHeuristicCard: {
        aiDesign: aiDesignEn,
        gameMap: gameMapEn,
        heuristicCard: heuristicCardEn,
        home: homeEn
      },
      artalkText: artalkEn
    }
  }
})

export default i18n
