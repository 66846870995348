import { ElButton, ElCarousel, ElPopover, ElIcon, ElInput, ElCheckTag, ElLoading } from 'element-plus'

const components = [ElButton, ElCarousel, ElPopover, ElIcon, ElInput, ElCheckTag, ElLoading]

/**
 * @description 批量按需注册element组件
 * @param app vue app实例
 */
const useElement = app => {
  components.forEach(component => {
    app.use(component)
  })
}

export default useElement
