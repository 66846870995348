export default {
  title: 'AI设计冲刺组件',
  titleDesc: 'AI设计冲刺组件旨在激发参与者在原型设计阶段产生创意灵感，拓展智能设计思路。',
  whatDesignSprint: '什么是AI设计冲刺？',
  desc1: '设计冲刺源于谷歌创投，其作用是帮助产品开发团队在短时间内基于关键的业务问题，围绕以用户为中心的，完成最小化产品的创新流程。与传统的设计冲刺相同，AI设计冲刺旨在高效地构建和验证原型设计。',
  desc2: '然而，与传统方法不同的是，设计团队需要根据项目背景，识别能够通过AI技术解决问题的设计机会点，了解AI技术如何发挥作用及预期效果，确认构建项目成功的技术路线图，以方便与工程师团队快速设计AI模型以实现项目目标。',
  desc3: '设计冲刺参考资料：https://designsprintkit.withgoogle.com/methodology/overview',
  title2: 'AI设计冲刺课堂实践',
  desc4: '为了提升学生们对于AI算法的理解，我们在卡片游戏的基础上开展设计冲刺课堂，期望设计学生们在设计实践中应用算法并潜移默化地提升他们对算法的理解力和应用能力。',
  desc5: '在课堂实践中，AI设计冲刺流程包括理解、定义、发散、决定、原型和分享这6个阶段。'
}
