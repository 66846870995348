export default {
  mounted() {
    // const script = require("busuanzi.pure.js");
  }

  // 监听,当路由发生变化的时候执行
  // watch: {
  //   $route(to, from) {
  //     if (to.path != from.path) {
  //       script.fetch();
  //     }
  //   }
  // }
};