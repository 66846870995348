export default {
  header: {
    home: 'Home',
    Copyright: 'Copyright',
    Dataset: 'Dataset',
    AboutUs: 'About Us',
    Inspiration: 'Inspiration',
    arTalk: 'ARTalk',
    poster: 'Cre-poster'
  },
  home: {
    WeCan: ' ',
    CopyrightProtection: 'Copyright Protection',
    text: 'Protect artwork from infringement through AI technology. The system can retrieve suspected plagiarized images helping intellectual protection. We also study invisible watermarks adding author information and timestamp to images.',
    ComingSoon: 'Coming soon',
    LoadMore: 'Load more',
    creativity: 'Helping protect your creativity from infringement',
    protection: 'Copyright protection based on AI technology',
    concept:
      'AI technology can help us improve the accuracy of extracting image features, both conceptual and visually, helping to build a faster and more accurate plagiarism image retrieval service. We also provide invisible watermarks for artworks to help trace the source of artworks.',
    tryNow: 'Try now',
    design: 'AI & Design',
    coming: 'Comming soon...',
    culturalRelicDesc: 'ARTalk & CrePoster',
    culturalRelicDetail:
      'Identifying the multi-dimensional features of artifacts and transforming them into professional descriptions, adaptively generating aesthetic artifact posters to promote the dissemination and reuse of cultural content.',
    helloAiDetail:
      'With the AI Card, common AI application scenarios, task models and algorithms are correlated through game settings to help designers deepen their understanding of the algorithms.',
    culturalImgDescLeft: 'Daoguang green jars with green dragon and pearl patterns on white ground in the Qing Dynasty are decorated with underglaze blue and white and overglaze green colors. They are also called Doucai green dragon pattern jars. They are a special variety of Doucai porcelain.',
    culturalImgDescRight1:
      'Intelligent Extraction and Representation of Cultural Artifact.',
    culturalImgDescRight2:
      'An AI-based system for automatic generation of artifact descriptions and posters.',
    culturalImgDescRight3:
      'The system is focusing on design-oriented data indexing for classical cultural prototypes to improve cultural element retrieval. Identifying and extracting multi-dimensional artifact features, enables the automatic generation of artifact descriptions and posters to facilitate the dissemination and reuse of classical cultural content.',
    helloAi: 'Hello AI!',
    helloAiRight1: '\'Hello!AI\' heuristic toolkit online platform',
    helloAiRight2: 'Design support based on knowledge of AI technologies',
    helloAiRight3:
      '\'Hello!AI\' Heuristic toolkit to help school students, working designers or beginners interested in AI technologies without the background to understand the principles of smart algorithms and quickly master the ideas and techniques of prototyping using AI technical knowledge.',
    inspection: 'Inspiration',
    inspectionDetail:
      'Gnosis, a creativity support system, is dedicated to deeper thought exploration, inspiration and metaphorical reasoning process visualization, and supports paradigm divergence from multiple design dimensions.',
    inspectionRight1: 'Design creativity support tool for intelligent design',
    inspectionRight2: 'Chinese style creativity support system',
    inspectionRight3:
      'In order to assist designers in constructing visual metaphors when designing Chinese-style posters, the key design elements and thinking paths for constructing visual metaphors for Chinese-style posters were proposed, and six types of element "metaphorical associations" were constructed using the semantic difference method and crowdsourcing method, and Based on "metaphoric association", an automatic generation rule for Chinese-style poster metaphor tags is proposed. "Gnosis" provides a retrieval function using "metaphor" as high-level semantic information, and supports the divergence of examples from multiple design dimensions of "metaphor", "color" and "form".',
  },
  footer: {
    contact: 'Contact us',
    prepare: 'ICP:湘ICP备2022012157号',
    address:
      'Taozihu, Pailou Road, Yuelu District, Changsha City, Hunan Province, China',
    visit: 'Total website visits ',
    time: ' times'
  },
  protection: {
    placeholderUrl:
      'Click the icon to upload an image, or enter the URL address of the image',
    localtionInputtext: 'Please enter your geographical location (Optional; Default value is your IP location)',
    originality: 'Protect your creativity from infringement',
    search: 'Search',
    explain:
      'The library images are collected from the Internet and are ONLY used for academic research. The search results cannot be used for other purposes.',
    use: 'How to use it? Load more.',
    copyButton: 'RUN',
    retrieval: 'Artwork Plagiarism Retrieval System',
    drag: 'Drag the image here or "ctrl+v" to copy it from the clipboard.',
    retrievalImage: 'Plagiarism Image Retrieval Technology Based on Deep Learning',
    localUpload: 'Upload pictures from local',
    text: 'Utilizing deep learning to extract multidimensional features from concepts and visuals, combined with expert knowledge to construct a faster and more accurate plagiarism image retrieval service. Currently, only the plagiarism detection system for poster design works is available online. Research articles have been published in the international conference ',
    text1: 'and the CCF A conference ',
    text2: '. Enjoy reading them! ',
    watermark: 'Added Invisible Watermark',
    artwork: 'Artwork Protection based on Invisible Watermark',
    longText:
      'By adding steganographic watermarks to artworks, designers can quickly embed hidden information. This technique facilitates swift evidence collection in cases of unauthorized use or reuse of the work. Relevant findings have been published in the CCF A conference ',
    markText1: 'WWW',
    markText2: 'and the CAS SCI1 zone journal ',
    markText3: 'TCSVT',
    markText4: '. Feel free to read them. ',
    tryNow: 'Try Now',
    probably: 'The picture might be',
    person: 'person',
    crane: 'crane',
    carp: 'carp',
    mountain: 'mountain',
    tiger: 'tiger',
    deer: 'deer',
    ancient: 'ancient archtecture',
    lotus: 'lotus',
    ImageInformation: 'Image information',
    detect: 'How to detect regularly?',
    plagiarism: 'Help us to assist plagiarism detection!',
    pictures: 'Pictures that may be plagiarized',
    click: 'Click on the image to see the comparison',
    similarity: 'Similarity',
    SimilarContent: 'Similar content',
    SearchResult: 'Search result',
    queryImage: 'Query image',
    result: 'Compare result',
    retrieved: 'What kind of images can be retrieved?',
    support: 'Supports png, jpg format etc.',
    format: 'Format: ',
    context: 'Context: ',
    contain:
      'Mainly Poster design artworks that include the following nine categories of the main object.',
    try: 'Try now!',
    pack: 'You can download the sample images below and upload them to retrieve the system.',
    zip: 'Example images.zip',
    explainImage:
      'All images in the search library are from the Internet, and images are stored and used',
    explainImageSeparate:
      'Don\'t hesitate to contact us if you find your work in our gallery and don\'t want to use it for image retrieval. We will delete your work immediately.',
    copyright: 'Copyright statement',
    research: 'ONLY for academic research.',
    loading: 'Loading',
    please: 'please waiting for',
    minutes: 'minutes',
    long: 'Waiting too long？',
    all: 'All',
    compare: 'compare',
    noSimilar: 'No similar pictures were identified',
  },
  noFind: {
    imageFrom: 'Image from storyset.com',
    pageLost: 'Whoops! Page lost！',
    agian: 'Try agian？',
    InProduction: 'Comming soon...',
    markInProduction: 'The watermark system is under development and testing',
    goBack: 'Home',
  },
  // 数据集资源
  resources: {
    posterDataset: 'Poster plagiarism dataset',
    sketch:
      'With the development of technology, the transmission mode of information in the network world has evolved from the original text-based mode to the era when images and videos are the mainstream. More and more designers and artists are willing to share their works on the Internet, and the acquisition of resources becomes simple and fast. In this era, plagiarism is getting more and more attention. Faced with the problem of plagiarism of art design works, we constructed this dataset--the first dataset of plagiarism of art works. We have collected a lot of plagiarism samples on the Internet and enriched our data set through retrieval tools. We invited designers to mark this dataset with us.',
    author: 'This dataset has been published on',
    acm: 'ACM Multimedia ',
    time: '2022.The co-authors are Shenglan Cui,',
    liu: 'Fang Liu',
    zhou: 'Tongqing Zhou',
    zhang: 'and Mohan Zhang.',
    symbol: ',',
    what: 'Dataset includes...',
    // type: 'It contains a total of 22,624 images.',
    // grouping: 'The dataset can be divided into 224 groups, each with 1 query image and 100 library images. Library images are annotated as plagiarized',
    type: 'All 32,624 images.',
    // grouping: '224 query images and 32,400 library images.',
    // division: ' and non-plagiarized.',
    division: '',
    contain: 'Mainly contain poster design works with modern painting style.',
    paper:
      'The dataset used in the experiment has been collected in the "data-MM" folder separately.',
    download: 'Download and reference',
    click: 'If you need to download this dataset, please',
    clickHere: 'click here',
    to: 'to fill out application form.',
    quote: 'If you use our dataset, please add the reference:',
    statement: 'Copyright statement',
    source:
      'All images in the search library are from the Internet, and images are stored and used',
    read: 'Please read the following statement before use.',
    definition: 'The definition of "plagiarism":',
    longtext:
      'Our dataset is annotated by experts\' experiences. Different experts have different boundaries and definitions for design plagiarism. Therefore, we only conclude whether each group of images may constitute plagiarism from designer experts. We did not consider factors such as the same author, open source materials, and the publish timestamp. Therefore, if query image X and library image Y are annotated as "plagiarized", this does not mean the author of image Y has copied image X. We do not want this dataset to cause discomfort or confusion to any creator.',
    creator: 'If you are a creator:',
    findWorks:
      'Please get in touch with us if you find your work in our dataset and do not want to use it for data training. We will delete your artwork immediately. We do not and will not use your work for commercial purposes.',
    researcher: 'If you are a researcher:',
    emphasize:
      'Again, the dataset should only be used for academic research. Commercial use or other acts that may infringe the copyright of images are strictly prohibited. Please abide by the copyright license terms of the original source of images.',
    update: 'Data update:',
    promise:
      'We promise to update the plagiarism dataset on our website regularly. ',
    errors: 'Errors in dataset:',
    errorsExplain:
      'All datasets are maintained manually, so there may inevitably be some errors. There is no implication or discrimination in the dataset, and it is not necessarily suitable for all purposes. We will try our best to reduce the probability of errors in each version of the dataset. If you find any problems, please let us know, and we will update them in time.',
    readThesis: 'Read thesis',
    application: 'Application',
    modernPoster: 'Modern poster dataset',
    modernText:
      'Because the artworks are usually abstract and complex, it is challenging to recognize object categories. Although Wikiart and BAW provide large painting datasets, there is still a lack of images of Asian culture. Therefore, we built Modern Poster Dataset, which mainly contains common artwork elements in East Asian culture, such as loong and crane. Meanwhile, we also provided labeling files for object recognition training.',
    sample:
      'There are 1978 samples in total, with 147-420 samples in each category.',
    try: 'We try our best to ensure that the dataset does not contain natural images.',
    category:
      '9 main categories: ancient architecture, carp, crane, deer, loong, lotus, mountain, person and tiger.',
    imgContain:
      'The pictures mainly include poster design works of modern painting style.',
    goApplication: 'Application',
  },
  aboutUs: {
    title: 'About us',
    laboratory:
      'DIDALab (Data Intelligence and Design innovAtion, Data Intelligence and Design Innovation Laboratory, Embroidery Tick Laboratory) is a "Design x Intelligence" interdisciplinary research laboratory established by Professor Liu Fang of the School of Design and Art of Hunan University. It was established in 2020 as a new research laboratory in the direction of intelligent design. The laboratory responds to the call of "New Engineering·New Design" of the School of Design and Art of Hunan University, attracting multi-disciplinary talents such as design and computer science, actively exploring interdisciplinary research theories, methods and practices, promoting the deep integration of technology and design, and promoting The construction and development of interdisciplinary design studies. ',
    research:
      'The main research directions of the laboratory include: human-intelligence collaborative design, cultural big data, computational aesthetics and visualization. For laboratory-related results, please refer to Professor Liu Fang’s personal homepage.',
    homepage: 'Prof. Fang Liu\'s homepage.',
  },
  poster: {
    titleDescription: 'What types of heritage posters can be generated?',
    formatDescription: 'Support jpg image upload',
    contextDescription: 'Cultural relic works mainly based on traditional ceramic works, generate posters with different formats according to the characteristics of cultural relics',
    pack: 'You can download the sample image shown in the following figure to try retrieving it.',
    automatically: 'CrePoster: Auto-generating Cultural Posters',
    automaticallySubtitle: 'Automatic Cultural Poster Generating System based on AI Technology',
    automaticallyDescription: 'CrePoster automatically extracts key visual elements of artifacts, identifies multi-dimensional visual features, and generates professional descriptions for artifact posters. The system begins by segmenting key visual objects and then uses a subtitle generator to extract multi-level features of artifacts. Finally, it incorporates attention mechanisms and artifact characteristics to influence poster layout, color schemes, and background design. Relevant research has been published in prestigious journals like ',
    automaticallyDescription3: ', ',
    automaticallyDescription1: ', and ',
    automaticallyDescription2: ', indexed in the top quartile of JCR and SCI under the Chinese Academy of Sciences. Enjoy reading! ',
    generateBtn: 'Run',
    posterTitle: 'Cre-poster Cultural Relic Poster Generation System',
    posterSubtitle: 'The library images are collected from the Internet and are ONLY used for academic research. The search results cannot be used for other purposes.',
    information: 'Cultural Relic Information:',
    result: 'Generated Results:',
    posterDownload: 'Download',
    location: 'Location',
    caption: 'Caption',
    description: 'Description',
    date: 'Date'
  }
}
