export default {
  title: 'Hello!AI Heuristic Cards',
  btns: {
    search: 'search',
    AlgorithmCard: 'Algorithm card',
    taskCard: 'AI task card',
    showAll: 'view all'
  },
  tips: {
    input: 'You can enter the name of the algorithm or AI task',
    select: 'Filter by data type:'
  },
  categoryType: {
    picture: 'picture',
    video: 'video',
    text: 'text',
    voice: 'voice',
    number: 'number'
  }
}
