import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import useElement from '@/plugins/useElement'
import '@/utils/amfe-flexible'
import '@/assets/style/index.css'
import 'animate.css'
import '@/assets/style/font.scss'
import i18n from '@/language/index'
import { createPinia } from 'pinia';
const pinia = createPinia();

const app = createApp(App)
app.use(i18n)
app.use(pinia);

useElement(app)

app.use(router).mount('#app')
