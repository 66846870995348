export default {
  title: "ARTalk操作面板",
  placeholderUrl: "点击右侧图标上传图像，或输入图片url地址",
  usage: "了解我们的更多工作！",
  accessPaper: "阅读论文",
  explain: "本平台图片均来自于网络，仅用于学术交流，请勿将生成内容用于商业目的。",
  generating: "描述生成",
  localUpload: "本地上传",
  drag: "拖拽图片到此处",
  KBplaceholder: "请输入关键词检索知识库...",
  imgProcessing: "图片解析中...",
  uploadNewImg: "点击上传新图像",
  newPattern: "框选添加纹样",
  color: "釉色",
  outlook: "器型",
  pattern: "纹样",
  label: "标签",
  unrecognition: "好像无法识别出来？",
  tryRefresh: "刷新试试？",
  tryHighDefinition: "可以尝试提高图片的清晰度或者保持光亮环境拍摄",
  save: "保存",
  edit: "编辑",
  cancel: "取消",
  copy: "复制",
}
