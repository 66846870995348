export default {
  title: "ARTalk operation panel",
  placeholderUrl: "Click the icon on the right to upload an image, or enter the image URL address",
  usage: "Learn more about our work! ",
  accessPaper: "Read the paper",
  explain: "The images on this platform are all from the Internet and are only used for academic communication. Please do not use the generated content for commercial purposes.",
  generating: "Run",
  localUpload: "Upload",
  drag: "Drag the image here",
  KBplaceholder: "Please enter keywords to search the knowledge base...",
  imgProcessing: "Picture parsing...",
  uploadNewImg: "Click to upload new image",
  newPattern: "Add patterns",
  color: "Glaze color",
  outlook: "Outlook",
  pattern: "Pattern",
  label: "Label",
  unrecognition: "Can't seem to recognize it?",
  tryRefresh: "Try refreshing?",
  tryHighDefinition: "You can try to improve the clarity of the picture or keep shooting in a bright environment",
  save: "Save",
  edit: "Edit",
  cancel: "Cancel",
  copy: "Copy",
}
