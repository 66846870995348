export default {
  title: 'AI Design Sprint Components',
  titleDesc: 'The AI design sprint components aim to inspire participants to generate creative inspiration in the prototyping stage and expand intelligent design ideas.',
  whatDesignSprint: 'What is Design Sprint?',
  desc1: 'Design sprint originated from Google Ventures. Its role is to help product development teams complete the innovation process of minimal products based on key business issues in a short period of time, centered around users. Like traditional design sprints, AI design sprints are designed to efficiently build and validate prototype designs.',
  desc2: 'However, different from traditional methods, the design team needs to identify design opportunities that can solve problems through AI technology based on the project background, understand how AI technology works and its expected effects, and confirm the technical roadmap for building a successful project, so as to facilitate cooperation with Teams of engineers quickly design AI models to achieve project goals.',
  desc3: 'Design sprint references: https://designsprintkit.withgoogle.com/methodology/overview',
  title2: 'AI design sprint classroom practice',
  desc4: "In order to improve students' understanding of AI algorithms, we carried out design sprint classes based on card games, expecting design students to apply algorithms in design practice and subtly improve their understanding and application ability of algorithms.",
  desc5: 'In classroom practice, the AI design sprint process includes six stages: understanding, definition, divergence, decision, prototype, and sharing.'
}
