export default {
  title: '游戏地图组件',
  titleDesc: '游戏地图采取桌游的形式，从寓教于乐的角度出发，旨在通过趣味化的学习互动体验加强参与者对算法卡与任务卡内容的记忆和理解',
  imgOneTitle: '《机器学习之旅》游戏地图',
  imgOneDesc: '《机器学习之旅》是采取积分制的竞技类卡牌地图游戏，并融合了策略类游戏的元素。一局游戏包括多个回合。游戏支持4-5人一组，其中包含1名裁判和3-4名玩家。在游戏过程中，玩家们需要主动陈述、关联和分辨算法能否解决AI应用所对应的AI任务模型。',
  imgTwoTitle: '随机事件卡',
  imgTwoDesc: '《机器学习之旅》是采取积分制的竞技类卡牌地图游戏，并融合了策略类游戏的元素。一局游戏包括多个回合。游戏支持4-5人一组，其中包含1名裁判和3-4名玩家。在游戏过程中，玩家们需要主动陈述、关联和分辨算法能否解决AI应用所对应的AI任务模型。',
  imgThreeTitle: '计分表',
  imgThreeDesc: '《机器学习之旅》是采取积分制的竞技类卡牌地图游戏，并融合了策略类游戏的元素。一局游戏包括多个回合。游戏支持4-5人一组，其中包含1名裁判和3-4名玩家。在游戏过程中，玩家们需要主动陈述、关联和分辨算法能否解决AI应用所对应的AI任务模型。',
}
