export default {
  title: 'Hello!AI启发式卡片',
  btns: {
    search: '检索',
    AlgorithmCard: '算法卡',
    taskCard: '任务卡',
    showAll: '显示全部'
  },
  tips: {
    input: '可输入算法或AI任务的名称',
    select: '按照数据输入类型进行筛选：'
  },
  categoryType: {
    picture: '图片',
    video: '视频',
    text: '文本',
    voice: '语音',
    number: '数值'
  }
}
