export default {
  header: {
    home: '首页',
    Copyright: '版权保护',
    Dataset: '数据集资源',
    AboutUs: '关于我们',
    Inspiration: '灵感启发',
    arTalk: '文物描述',
    poster: '文物海报'
  },
  home: {
    WeCan: ' ',
    CopyrightProtection: '版权保护',
    text: '通过AI技术，为图像提供版权保护。可对疑似抄袭图像进行检索，并为设计图象添加不可见水印',
    ComingSoon: '敬请期待',
    LoadMore: '了解更多',
    creativity: '为你的创意保驾护航',
    protection: '基于人工智能技术的版权保护策略',
    concept:
      '基于人工智能技术提升提取图像特征的准确性，从概念和视觉两个角度融合多维度特征，构建更快速和准确的抄袭图像检索服务。同时，我们为设计图像提供隐写水印，帮助图片的溯源，帮助设计作品确权。',
    tryNow: '立即体验',
    design: '技术&设计',
    coming: '开发设计中……',
    culturalRelicDesc: '文物描述与海报生成',
    culturalRelicDetail:
      '识别文物多维特征并将其转化为专业描述，自适应地生成美学文物海报，促进文化内容的传播和再利用。',
    helloAiDetail:
      '配合AI卡牌的使用，通过游戏设置，把AI常见的应用场景、任务模型和算法进行关联，帮助设计师加深对算法的理解。',
    culturalImgDescLeft: '清道光白地绿彩龙赶珠纹盖罐 以釉下青花金额釉上绿彩装饰，又称斗彩绿龙纹罐，是斗彩瓷器中的特殊品种。',
    culturalImgDescRight1: '文物知识智能提取与表征',
    culturalImgDescRight2: '基于人工智能技术的文物描述自动生成与海报自动生成系统',
    culturalImgDescRight3:
      '针对经典文化原型进行面向设计创意的数据标引，优化文化要素检索。通过识别和提取文物多维特征，实现文物描述和文物海报的自动生成，促进经典文化内容的传播与再利用。',
    helloAi: 'Hello AI!',
    helloAiRight1: 'Hello!AI启发式工具包线上平台',
    helloAiRight2: '基于人工智能技术知识的设计支持',
    helloAiRight3:
      'Hello!AI启发式工具包，可帮助无人工智能技术背景的在校学生、在职设计师或对智能技术感兴趣的初学者理解智能算法原理，并快速掌握利用人工智能技术知识进行原型设计的思路与技巧。',
    inspection: '灵感启发',
    inspectionDetail:
      '灵知，一个创造力支持系统，致力于更深层次的思维探索、灵感启发与隐喻推理过程可视化，支持从多个设计维度进行范例发散。',
    inspectionRight1: '面向智能设计的设计创造力支持工具',
    inspectionRight2: '中国风格创造力支持系统',
    inspectionRight3:
      '为了能够辅助设计师在设计中国风格海报时构建视觉隐喻，提出了中国风格海报视觉隐喻构建的关键设计要素与思维路径，使用语义差异法与众包法构建了六类元素“隐喻关联”，并基于“隐喻关联”提出了中国风格海报隐喻标签自动生成规则。“灵知”，提供以“隐喻”作为高层语义信息的检索功能，支持从“隐喻”“色彩”和“形态”多个设计维度进行范例发散。',
  },
  footer: {
    contact: '联系我们',
    prepare: '湘ICP备2022012157号',
    address: '湖南省长沙市岳麓区牌楼路桃子湖湖南大学设计艺术学院',
    visit: '本站总访问量',
    time: '次'
  },
  protection: {
    placeholderUrl: '点击右侧图标上传图像，或输入图片url地址',
    localtionInputtext: '请输入您的地理位置（这是可选的，默认值为您的地理信息）',
    originality: '为你的创意保驾护航',
    search: '抄袭检索',
    explain:
      '本平台图片均来自于网络，仅用于学术交流，不可将检索结果用于其他目的。',
    use: '如何使用？查看检索案例！',
    copyButton: '抄袭检索',
    retrieval: '设计作品查重系统',
    drag: '拖拽图片到此处或ctrl+v从剪切板复制图片',
    retrievalImage: '基于深度学习技术的抄袭图像检索技术',
    localUpload: '从本地上传图片',
    text: '基于深度学习技术从概念和视觉提取多维度融合特征，结合专家经验面向常见抄袭手段构建更快速和准确的抄袭图像检索服务。目前仅上线海报设计作品的查重系统，欢迎使用。相关文章发表在设计学国际期刊',
    text1: '和CCF A类会议',
    text2: '上，欢迎阅读。',
    watermark: '添加隐写水印保护',
    artwork: '基于隐写水印的设计作品保护方法',
    longText:
      '基于隐写水印的设计作品保护方法通过为作品添加隐写水印，设计师可以快速为作品加入隐写信息。当作品被盗用、复用时，可以帮助快速取证。相关成果已发表在CCF A类会议WWW中和中科院SCI1区期刊TCSVT中，欢迎阅读。',
    markText1: 'WWW',
    markText2: '和中科院SCI1区期刊',
    markText3: 'TCSVT',
    markText4: '，欢迎交流。',
    tryNow: '立即体验',
    probably: '图片中的可能是',
    person: '人物',
    dragon: '龙',
    crane: '仙鹤',
    carp: '锦鲤',
    mountain: '山',
    tiger: '老虎',
    deer: '鹿',
    ancient: '古代建筑',
    lotus: '荷花',
    ImageInformation: '图片信息',
    detect: '如何进行定期检测？',
    plagiarism: '帮助我们助力抄袭检测！',
    pictures: '可能抄袭的图片',
    click: '点击图片查看对比',
    similarity: '相似度',
    SimilarContent: '相似内容',
    SearchResult: '查询结果',
    queryImage: '你的图像',
    result: '比较结果',
    retrieved: '能检索什么类型的图片？',
    support: '支持png, jpg 等内容的检索',
    format: '格式：',
    context: '内容：',
    contain: '包含以下九类主体内容的海报设计作品',
    try: '试一试！',
    pack: '您可以下载下图中所展示的示例图片尝试检索。',
    zip: '打包下载',
    explainImage: '检索库的所有图片均来源于互联网，上述图片的保存和使用',
    explainImageSeparate:
      '如果您在我们的图库中找到了您的作品，并且不想将其用于图像检索工作，请与我们联系。我们将立即删除您的作品。',
    copyright: '版权说明',
    research: '均仅用于学术研究。',
    loading: '计算中',
    please: '预计等待',
    minutes: '分钟',
    long: '等待太久？',
    all: '全部',
    compare: '重叠比较',
    noSimilar: '未识别到相似图片',
  },
  noFind: {
    imageFrom: '图源 storyset.com',
    pageLost: '抱歉，页面丢失',
    agian: '刷新试试？',
    InProduction: '相关内容制作中',
    markInProduction: '水印系统正在开发测试中',
    goBack: '返回首页',
  },
  // 数据集资源
  resources: {
    posterDataset: '关于海报抄袭数据集',
    sketch:
      '随着技术的发展，网络世界中信息的传递模式已经从最初的文字为主的模式，演变成了图像和视频为主流的时代。越来越多的设计师和艺术家们也愿意在网络上分享自己的作品，资源的获取变得简单、快速在这样的时代背景下，抄袭问题也越来越被重视。面对艺术设计作品被抄袭的问题，我们构建了这个数据集——第一个艺术作品抄袭数据集。我们在互联网收集了很多抄袭样本，并通过检索工具丰富我们的数据集。我们邀请了设计师与我们一起标注了这个数据集。',
    author: '该数据集已发表在',
    acm: 'ACM Multimedia ',
    time: '2022上。作者为崔盛兰，',
    liu: '刘芳',
    zhou: '周桐庆',
    zhang: '张默涵。',
    symbol: '，',
    what: '数据集里有什么?',
    type: '一共包含32，624张图片。',
    // grouping: '分为224组，每组图片有1张查询图片(query image)和100张图库图片(library images)，图库图片被分为“抄袭”',
    // grouping: '有224张查询图片和32400张库图片(库图片持续更新中)。',
    // division: '(plagiarized) 和“非抄袭”(non-plagiarized)。',
    division: '',
    contain: '图片主要包含现代绘画风格的海报设计作品。',
    paper: '论文实验中所用到的数据集合被单独放在“data-MM”文件夹中。',
    download: '下载与引用',
    click: '如果需要下载此数据集，请',
    clickHere: '点击此处',
    to: '填表发送请求。',
    quote: '如您使用我们的数据集，请引用MM论文：',
    statement: '版权说明',
    source: '数据集中的所有图片均来源于互联网，上述图片的保存和使用',
    read: '请在使用前阅读如下声明。',
    definition: '关于“抄袭”的定义：',
    longtext:
      '我们的数据集由专家的经验注释。不同的专家对设计抄袭有不同的界限和定义。因此，我们只得出每组图像是否可能构成设计师专家抄袭的结论。我们没有考虑同一作者、开源素材和发布时间等因素。因此，如果查询图像 X 和库图像 Y 被注释为“抄袭”，这并不意味着图像 Y 的作者复制了图像 X。我们不希望此数据集给任何创建者带来不适或困惑，也请任何使用者禁止向作品原作者进行声誉攻击或其他不礼貌的行为。',
    creator: '如果您是创作者：',
    findWorks:
      '如果您在我们的数据集中找到您的作品，并且不想将其用于数据训练，请与我们联系。我们将立即删除您的作品。',
    researcher: '如果您是研究人员：',
    emphasize:
      '再次强调，数据集应仅用于学术研究。严禁商业使用或其他可能侵犯图像版权的行为。请您严格遵守该图片原出处所指定的版权许可条款。',
    update: '关于数据更新：',
    promise:
      '我们承诺定期更新我们网站上最新版本的抄袭数据集。考虑到作品复制权的问题，我们将会在未来更新仅带有url的数据库，进一步保护作品原作者权益。',
    errors: '关于数据集中的错误：',
    errorsExplain:
      '数据集均为人工维护，因此不可避免地可能存在一些错误。数据集没有任何的暗示或者歧视存在，且并不一定是适用于所有目的。每一版本的数据集我们都会尽可能降低错误产生的概率，如果您发现任何问题，欢迎告知，我们将会及时更新：cuishenglan{\'@\'}gmail.com。',
    readThesis: '阅读论文',
    application: '申请下载',
    modernPoster: '关于现代海报数据集',
    modernText:
      '由于艺术作品图像通常是抽象的、复杂的，在面对目标识别网络时，往往展现较低的准确率。虽然也有wikiart这种艺术品图像数据集，但是目前的数据集中缺乏现代数字艺术作品。同时，在亚洲文化中较常见的设计元素，龙、仙鹤等目标也缺乏训练。因此，我们构建了一个现代海报数据集，主要包含东亚文化中常见的设计元素。我们使用labelme对数据进行了标注，我们同时提供标注文件。',
    sample: '一共包含1978个样本，每个类别有147-420个样本。',
    try: '我们尽可能确保数据集中不包含自然图像。',
    category:
      '包含9类主要类别：古代建筑，锦鲤，仙鹤，鹿，龙，莲花，山，人，老虎。',
    imgContain: '图片主要包含现代绘画风格的海报设计作品。',
    goApplication: '前往下载',
    ChineseStyleDatabaseTitle: '中国设计风格的经典文化元素及原型数据库',
    ChineseStyleDatabaseDesc1: '在国家重点研发计划课题“中国设计风格的经典文化元素及原型数据库研发”的支持下，研发的“中国设计风格的经典文化元素及原型数据库”平台，具备文化资源聚合、加工、开放等多方面的功能，促进文化知识的挖掘和储备、实现文化资源的个性化智能推荐、提升设计素材的生成和分发，对创意设计过程的各个环节提供支撑。',
    ChineseStyleDatabaseDesc2: '该数据库收录数据约20000条，文化设计数据量超过1PB，支持多种文化风格和设计资源的智能生成。',
    ChineseStyleDatabaseDesc3: '更详细内容请访问',
    ChineseStyleDatabaselink1: '数据库官网',
    ChineseStyleDatabaseDesc4: '，下面为部分数据库网页截图，仅供预览。',
    ChineseStyleDatabaselink2: '访问官网'
  },
  aboutUs: {
    title: '关于我们',
    laboratory:
      'DIDALab（Data Intelligence and Design innovAtion，数据智能与设计创新实验室，又称滴答lab）是湖南大学设计艺术学院刘芳教授成立的“设计x智能”跨学科研究实验室。作为在智能设计方向进行探究新生实验室，于2020年成立。实验室响应湖南大学设计艺术学院“新工科·新设计”号召，吸纳设计学和计算机科学等多学科人才，在跨学科研究理论、方法和实践上积极探索，促进技术与设计的深度融合，推动设计学交叉学科的建设和发展。',
    research:
      '实验室主要的研究方向包括：人智协同设计、文化大数据、计算美学与可视化。实验室相关成果请参考刘芳教授个人主页。',
    homepage: '刘芳教授个人主页。',
  },
  poster: {
    titleDescription: '能生成何种类型的文物海报？',
    formatDescription: '支持 jpg 图像的上传',
    contextDescription: '以传统陶瓷作品为主的文物作品，按照文物特点生成不同版式的海报',
    pack: '您可以下载下图中所展示的示例图片尝试检索。',
    automatically: '文物海报自动生成',
    automaticallySubtitle: '基于人工智能技术的文物海报自动生成系统',
    automaticallyDescription: 'CrePoster文物海报自动生成系统，提取文物关键视觉元素，识别文物多维度视觉特征并生成专业描述。结合专业海报模板与自适应的配色方案，生成兼具美学价值与社交分享性的文物海报。系统首先对关键视觉对象进行分割；随后使用字幕生成器提取多维度文物特征；最后结合注意力机制，文物特点等信息共同指导海报布局、配色和背景设计。相关工作已发表在中科院SCI一区期刊',
    automaticallyDescription3: '，',
    automaticallyDescription1: '和JCR一区期刊',
    automaticallyDescription2: '中，欢迎阅读。',
    generateBtn: '海报生成',
    posterTitle: 'Cre-poster 文物海报生成系统',
    posterSubtitle: '本平台训练用图片均来自网络，仅用于学术交流，请勿将生成内容用于商业目的。为实现较好的生成结果，请提交清晰、没有较大反光点，拍摄居中的图片。',
    information: '文物信息',
    result: '生成结果',
    posterDownload: '海报下载',
    location: '上传地址',
    caption: '文物名称',
    description: '文物描述',
    date: '拍摄时间'
  }
}
