import { defineStore } from 'pinia'

export const useGlobalStore = defineStore('global', {
  state: () => ({
    languageValue: localStorage.getItem('language') || 'zh',
  }),
  actions: {
    // 修改多语言
    setValue (newValue) {
      this.languageValue = newValue
    },
  },
})
