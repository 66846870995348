export default {
  menu: {
    home: 'Home',
    heuristicCards: 'Heuristic Cards',
    gameMapComponents: 'Game Map Components',
    aIDesignSprintComponents: 'AI Design Sprint Components',
  },
  introduction: 'How to play',
  developing: 'The website is under development',
  topTitle: 'Hello!AI Heuristic Toolkit Online Platform',
  whatHelloAI: 'What is the Hello!AI Heuristic Toolkit',
  use: 'Hello!AI heuristic toolkit, which can help school students, designers or beginners without artificial intelligence technology background interested in intelligent technology understand the principles of intelligent algorithms, and quickly grasp the idea of using artificial intelligence technology knowledge for prototyping with skill.',
  black: 'Open the "black box" of AI technology',
  game: 'Through heuristic cards and game map components, help beginners better understand the concept, principle and application scenarios of intelligent algorithms, and improve the fun of learning.',
  AiDesign: 'Inspire intelligent design idea generation',
  ideas: 'In the prototyping stage, the Hello!AI heuristic toolkit can inspire designers to use AI technology knowledge to generate ideas and broaden design ideas.',
  technicalPath: 'Clarify the technical path',
  apply: 'By providing AI design sprint components, it helps designers clarify the technical implementation path in the prototyping stage, and facilitates collaboration and communication with engineers in the later stages of the project.',
  footerTitle: 'The Hello!AI heuristic toolkit includes the following three types of components',
  heuristicCard: 'Heuristic cards',
  heuristicCardDesc: 'Contains algorithm cards and AI task cards, providing basic knowledge of algorithms and AI task models.',
  gameMap: 'Game map components',
  gameMapDesc: 'Contains game map components such as game canvas and random event cards to help participants better understand card knowledge during board game interaction.',
  AiDesignComponent: 'AI Design Sprint Components',
  AiDesignComponentDesc: 'Contains AI design sprint components such as design sprint canvas and mindstorming canvas to help participants expand intelligent design ideas and clarify technical paths.'
}
