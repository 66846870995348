export default {
  title: 'Game map components',
  titleDesc: "The game map takes the form of a board game. It aims to enhance participants' memory and understanding of the content of algorithm cards and AI task cards through interesting learning and interactive experiences.",
  imgOneTitle: '"Journey of Machine Learning" game map',
  imgOneDesc: '"Journey of Machine Learning" is a point-based competitive card map game that incorporates elements of strategy games. A game consists of multiple rounds. The game supports a group of 4-5 people, including 1 referee and 3-4 players. During the game, players need to actively state, correlate and distinguish whether the algorithm can solve the AI task model corresponding to the AI application.',
  imgTwoTitle: 'Random Event cards',
  imgTwoDesc: '"Journey of Machine Learning" is a point-based competitive card map game that incorporates elements of strategy games. A game consists of multiple rounds. The game supports a group of 4-5 people, including 1 referee and 3-4 players. During the game, players need to actively state, correlate and distinguish whether the algorithm can solve the AI task model corresponding to the AI application.',
  imgThreeTitle: 'Score Table',
  imgThreeDesc: '"Journey of Machine Learning" is a point-based competitive card map game that incorporates elements of strategy games. A game consists of multiple rounds. The game supports a group of 4-5 people, including 1 referee and 3-4 players. During the game, players need to actively state, correlate and distinguish whether the algorithm can solve the AI task model corresponding to the AI application.',
}
