export default {
  menu: {
    home: '首页',
    heuristicCards: '启发式卡片',
    gameMapComponents: '游戏地图组件',
    aIDesignSprintComponents: 'AI设计冲刺组件',
  },
  introduction: '玩法介绍',
  developing: '目前尚在开发中',
  topTitle: 'Hello!AI启发式工具包线上平台',
  whatHelloAI: '什么是Hello!AI启发式工具包',
  use: 'Hello!AI启发式工具包，可帮助无人工智能技术背景的在校学生、在职设计师或对智能技术感兴趣的初学者理解智能算法原理，并快速掌握利用人工智能技术知识进行原型设计的思路与技巧。',
  black: '打开AI技术“黑盒子”',
  game: '通过启发式卡片和游戏地图组件，帮助初学者更好地理解智能算法的概念、原理以及应用场景，提高学习趣味性。',
  AiDesign: '激发智能设计创意生成',
  ideas: '在原型设计阶段，Hello!AI启发式工具包能激发设计师利用AI技术知识进行创意生成，拓宽设计思路。',
  technicalPath: '理清技术路径',
  apply: '通过提供AI设计冲刺组件，帮助设计师在原型设计阶段理清技术实现路径，有助于在项目后期阶段与工程师的协作沟通。',
  footerTitle: 'Hello!AI启发式工具包包括以下三类组件',
  heuristicCard: '启发式卡片',
  heuristicCardDesc: '包含算法卡以及任务卡，提供算法及AI任务模型的基础知识',
  gameMap: '游戏地图组件',
  gameMapDesc: '包含游戏画布、随机事件卡等游戏地图组件，帮助参与者在桌游互动更好地理解卡片知识',
  AiDesignComponent: 'AI设计冲刺组件',
  AiDesignComponentDesc: '包含设计冲刺画布、发散画布等AI设计冲刺组件，帮助参与者拓展智能设计思路以及理清技术路径'
}
