import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "footer"
};
import Header from '@/components/Header';
import PageFooter from '@/components/PageFooter';
export default {
  __name: 'App',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_unref(Header), {
        "background-color": "#000000 0.2"
      }), _createVNode(_component_router_view), _createElementVNode("div", _hoisted_1, [_createVNode(_unref(PageFooter))])], 64);
    };
  }
};