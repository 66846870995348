import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue')
  },
  {
    path: '/CopyrightProtection',
    name: 'CopyrightProtection',
    component: () => import(/* webpackChunkName: "CopyrightProtection" */ '@/views/CopyrightProtection.vue')
  },
  {
    path: '/ComingSoon',
    name: 'ComingSoon',
    component: () => import(/* webpackChunkName: "ComingSoon" */ '@/views/ComingSoon.vue')
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: () => import(/* webpackChunkName: "ComingSoonTwo" */ '@/views/AboutUs.vue')
  },
  {
    path: '/ComingSoonThree',
    name: 'ComingSoonThree',
    component: () => import(/* webpackChunkName: "ComingSoonThree" */ '@/views/ComingSoon.vue')
  },
  {
    path: '/Resources',
    name: 'Resources',
    component: () => import(/* webpackChunkName: "ComingSoonThree" */ '@/views/Resources.vue')
  },
  {
    path: '/Artalk',
    name: 'Artalk',
    component: () => import('@/views/Artalk/Index.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */'@/views/notFound.vue')
  },
  // AI启发式卡片
  {
    path: '/AIHeuristicCard/home',
    name: 'AIHeuristicCardHome',
    component: () => import('@/views/AIHeuristicCard/Home/Index.vue')
  },
  {
    path: '/AIHeuristicCard/heuristicCard',
    name: 'HeuristicCard',
    component: () => import('@/views/AIHeuristicCard/HeuristicCard/Index.vue')
  },
  {
    path: '/AIHeuristicCard/gameMap',
    name: 'GameMap',
    component: () => import('@/views/AIHeuristicCard/GameMap/Index.vue')
  },
  {
    path: '/AIHeuristicCard/aiDesign',
    name: 'AiDesign',
    component: () => import('@/views/AIHeuristicCard/AIDesign/Index.vue')
  },
  {
    path: '/CrePoster',
    name: 'CrePoster',
    component: () => import('@/views/Poster.vue')
  },
  {
    path: '/Game',
    name: 'Game',
    component: () => import('@/views/Game/Index.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
